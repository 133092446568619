import React, { useEffect, useState } from "react";
import "./Create.css";
import ParagraphInput from "./ParagraphInput";
import { auth, db, storage } from "../../FirebaseConfig";
import { ref, push } from "firebase/database";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytesResumable,
} from "firebase/storage";
import { format } from "date-fns";

import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function Create() {
  const [heroImage, setHeroImage] = useState();
  const [thumbnail, setThumbnail] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(auth?.currentUser?.uid);
  }, []);

  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [synopsis, setSynopsis] = useState("");
  const [category, setCategory] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [authorPhoto, setAuthorPhoto] = useState("");
  const [isPinned, setIsPinned] = useState(false);
  const [readTime, setReadTime] = useState("");
  const [isPosting, setIsPosting] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState({
    progress: 0,
    name: "",
  });

  const handleFileChange = (e, setState) => {
    const file = e.target.files[0];
    if (file) {
      setState(URL.createObjectURL(file));
    } else {
      setState(null); // Handle the case where no file is selected
    }
  };

  const onPost = async () => {
    setIsPosting(true);
    // Check if all required fields are filled
    if (
      !title ||
      !subTitle ||
      !synopsis ||
      !category ||
      !thumbnail ||
      !authorName ||
      !authorPhoto ||
      !readTime
    ) {
      alert("Please fill in all fields.");
      setIsPosting(false);
      return;
    }
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    // Implement sending email logic here

    // Check if at least one paragraph is provided
    if (rawContentState.length === 0) {
      alert("Please provide at least one paragraph.");
      setIsPosting(false);
      return;
    }

    try {
      const currentDate = format(new Date(), "MMMM dd, yyyy");

      // Upload images to Firebase Storage and get download URLs
      const thumbnailUrl = await uploadImageToStorage(
        thumbnail,
        "thumbnails",
        "Thumbnail",
        `${Date.now()}_${title.replace(/ /g, "")}`
      );

      const heroImageUrl = await uploadImageToStorage(
        heroImage,
        "heroImages",
        "hero image",
        `${Date.now()}_${title.replace(/ /g, "")}`
      );

      const authorPhotoUrl = await uploadImageToStorage(
        authorPhoto,
        "authorPhotos",
        "author photo",
        `${Date.now()}_${authorName.replace(/ /g, "")}`
      );

      // Store blog data in Firebase Realtime Database
      const postRef = ref(db, "blogsData");
      const data = {
        title: title,
        subTitle: subTitle,
        description: synopsis,
        category: category,
        thumbnail: thumbnailUrl,
        heroImage: heroImageUrl,
        blogContent: rawContentState,
        authorName: authorName,
        authorPhoto: authorPhotoUrl,
        date: currentDate,
        isPinned: isPinned,
        readTime: readTime,
      };
      await push(postRef, data);

      // Clear all fields after successful upload
      setTitle("");
      setSubTitle("");
      setSynopsis("");
      setCategory("");
      setThumbnail("");
      setHeroImage("");
      setAuthorName("");
      setAuthorPhoto("");
      setIsPinned(false);
      setReadTime("");

      // Navigate to home page if needed
      // navigate("/");
      window.location.href = "/";
    } catch (error) {
      console.error("Error uploading data:", error);
      alert("An error occurred while posting. Please try again later.");
    } finally {
      setIsPosting(false);
    }
  };

  const uploadImageToStorage = async (
    image,
    folder,
    nameOfTheImage,
    imageName
  ) => {
    if (!image || !image.startsWith("blob")) return ""; // If no image or not a blob URL, return empty string
    console.log(folder);
    try {
      const response = await fetch(image);
      const blob = await response.blob();

      const imageRef = storageRef(storage, `${folder}/${imageName}`);
      const uploadTask = uploadBytesResumable(imageRef, blob);

      // Create a Promise to await getDownloadURL
      const downloadUrlPromise = new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress_ = snapshot.bytesTransferred / snapshot.totalBytes;
            setUploadingProgress({
              progress: (progress_ * 100).toFixed(),
              name: nameOfTheImage,
            });
          },
          (error) => {
            // handle error
            reject(error);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((downloadURL) => {
                resolve(downloadURL);
              })
              .catch((error) => {
                reject(error);
              });
          }
        );
      });

      // Wait for the download URL and return it
      return await downloadUrlPromise;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const handleEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  return (
    <div className="Create">
      <div className="create-content">
        <input
          type={"text"}
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <input
          type={"text"}
          placeholder="Subtitle"
          value={subTitle}
          onChange={(e) => setSubTitle(e.target.value)}
        />
        <textarea
          className="synopsis"
          placeholder="Synopsis"
          value={synopsis}
          onChange={(e) => setSynopsis(e.target.value)}
        ></textarea>
        <h3>Categories</h3>
        <div className="categories">
          <div className="category">
            <input
              type={"radio"}
              value="Governance"
              checked={category === "Governance"}
              onChange={() => setCategory("Governance")}
            />
            <label>Governance</label>
          </div>
          <div className="category">
            <input
              type={"radio"}
              value="Development"
              checked={category === "Development"}
              onChange={() => setCategory("Development")}
            />
            <label>Development</label>
          </div>
          <div className="category">
            <input
              type={"radio"}
              value="Education"
              checked={category === "Education"}
              onChange={() => setCategory("Education")}
            />
            <label>Education</label>
          </div>
        </div>

        <div className="images">
          <div className="image">
            <p>Select a thumbnail image</p>
            <input
              type="file"
              placeholder="Thumbnail image"
              onChange={(e) => handleFileChange(e, setThumbnail)}
            />
          </div>
          <div className="image">
            <p>Select a hero image</p>
            <input
              type="file"
              placeholder="Hero image"
              onChange={(e) => handleFileChange(e, setHeroImage)}
            />
          </div>
        </div>

        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorStateChange}
          toolbar={{
            options: [
              "inline",
              "blockType",
              "fontSize",
              "list",
              "textAlign",
              "remove",
              "history",
            ],
            inline: { inDropdown: false },
            list: { inDropdown: false },
            textAlign: { inDropdown: false },
            link: { inDropdown: false },
            history: { inDropdown: false },
          }}
          placeholder="Write your blog..."
          editorStyle={{
            minHeight: "50vh",
            backgroundColor: "#f4f4f4",
            padding: 10,
            marginBottom: "4vh",
            borderRadius: 10,
          }}
        />

        <div className="meta-data">
          <div>
            <h3>What will be the read time</h3>
            <input
              className="read-time"
              type="number"
              placeholder="Read time in minutes"
              value={readTime}
              onChange={(e) => setReadTime(e.target.value)}
            />
          </div>

          <div className="pinning">
            <h3>Will this blog be pinned at the main</h3>
            <div className="pins">
              <div className="pin">
                <input
                  type="radio"
                  value="yes"
                  checked={isPinned}
                  onChange={() => setIsPinned(true)}
                />
                <label>Pinned</label>
              </div>
              <div className="pin">
                <input
                  type="radio"
                  value="no"
                  checked={!isPinned}
                  onChange={() => setIsPinned(false)}
                />
                <label>Not Pinned</label>
              </div>
            </div>
          </div>
        </div>
        <div className="author-details">
          <input
            type={"text"}
            placeholder="Author's Name"
            value={authorName}
            onChange={(e) => setAuthorName(e.target.value)}
          />
          <div>
            <p>Select author's photo</p>
            <input
              type="file"
              placeholder="Author's photo"
              onChange={(e) =>
                setAuthorPhoto(URL.createObjectURL(e.target.files[0]))
              }
            />
          </div>
        </div>
        <div
          className="submit-btn"
          onClick={() => onPost()}
          style={
            isPosting
              ? {
                  backgroundColor: "black",
                  color: "white",
                  width: "auto",
                  paddingRight: 10,
                  paddingLeft: 10,
                  textTransform: "capitalize",
                }
              : {}
          }
        >
          {isPosting
            ? `Uploading ${uploadingProgress.name} ${uploadingProgress.progress}%...`
            : "Post"}
        </div>
      </div>
    </div>
  );
}
