import React, { useEffect, useState } from "react";
import FeaturedCard from "./FeaturedCard";
import "./FeaturedView.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

export default function FeaturedView({ data, index }) {
  const [isMobileView, setIsMobileView] = useState(false);
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 992) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
      }
    });
    if (window.innerWidth < 992) {
      setIsMobileView(true);
    } else {
      setIsMobileView(false);
    }
  }, []);
  return (
    <div className="featured-view">
      <h2 className="title">{data.category}</h2>
      <p className="desc">{data.description}</p>
      <div
        className="featured-view-container"
        style={
          !isMobileView && index === 1 ? { flexDirection: "row-reverse" } : {}
        }
      >
        <div
          className="featured-main"
          style={{ backgroundImage: `url(${data.main.thumbnail})` }}
        >
          <div className="featured-main-container">
            <h2>{data.main.title}</h2>
            <div className="acknowledgements" style={{ marginTop: 30 }}>
              <div className="right">
                <img
                  alt="author"
                  className="author-pic"
                  src={data.main.author.photo}
                />
                <p>by</p>
                <h3>{data.main.author.name}</h3>
              </div>
              <div className="left">
                <AccessTimeIcon className="icon" />
                <p>{data.main.date}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="featured-list">
          {data.blogs.map(({ title, thumbnail, author, date, tag }, index) => (
            <FeaturedCard
              key={index}
              title={title}
              thumbnail={thumbnail}
              author={author}
              date={date}
              tag={tag}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
