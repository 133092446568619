import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Read.css";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/features/userSlice";
import { onValue, ref, remove } from "firebase/database";
import { db } from "../../FirebaseConfig";

export default function Read() {
  const location = useLocation();
  const { state } = location;
  const user = useSelector(selectUser);

  const [color, setColor] = useState(null);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [styles, setStyles] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    const random_colors = ["blue", "rgb(2, 104, 2)", "red", "black", "purple"];
    setColor(random_colors[Math.floor(Math.random() * random_colors.length)]);
  }, []);

  // useEffect(() => {
  //   if (state.blogContent) {
  //     Object.values(state.blogContent)[0].forEach((item) => {
  //       // Handle inline styles
  //       if (item.inlineStyleRanges) {
  //         item.inlineStyleRanges.forEach((range) => {
  //           switch (range.style) {
  //             case "BOLD":
  //               setStyles((prevStyles) => ({
  //                 ...prevStyles,
  //                 [item.key]: { fontWeight: "bold", ...prevStyles[item.key] },
  //               }));
  //               break;
  //             case "UNDERLINE":
  //               setStyles((prevStyles) => ({
  //                 ...prevStyles,
  //                 [item.key]: {
  //                   textDecoration: "underline",
  //                   ...prevStyles[item.key],
  //                 },
  //               }));
  //               break;
  //             case "ITALIC":
  //               setStyles((prevStyles) => ({
  //                 ...prevStyles,
  //                 [item.key]: { fontStyle: "italic", ...prevStyles[item.key] },
  //               }));
  //               break;
  //             // Add cases for other inline styles as needed
  //             default:
  //               break;
  //           }
  //         });
  //       }

  //       // Handle block types
  //       switch (item.type) {
  //         case "header-one":
  //           setStyles((prevStyles) => ({
  //             ...prevStyles,
  //             [item.key]: { fontSize: "32px", ...prevStyles[item.key] },
  //           }));
  //           break;
  //         case "header-two":
  //           setStyles((prevStyles) => ({
  //             ...prevStyles,
  //             [item.key]: { fontSize: "24px", ...prevStyles[item.key] },
  //           }));
  //           break;
  //         case "unordered-list-item":
  //           setStyles((prevStyles) => ({
  //             ...prevStyles,
  //             [item.key]: { listStyleType: "disc", ...prevStyles[item.key] },
  //           }));
  //           break;
  //         case "ordered-list-item":
  //           setStyles((prevStyles) => ({
  //             ...prevStyles,
  //             [item.key]: { listStyleType: "decimal", ...prevStyles[item.key] },
  //           }));
  //           break;
  //         // Add cases for other block types as needed
  //         default:
  //           break;
  //       }

  //     });
  //   }
  // }, [state.blogContent]);

  const [blogData, setblogData] = useState({});

  const { pathname } = useLocation();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const starCountRef = ref(db, "blogsData");
    const unsubscribe = onValue(starCountRef, (snapshot) => {
      try {
        const data = snapshot.val();
        if (data) {
          setblogData(
            data[[pathname.split("/")[pathname.split("/").length - 1]]]
          );
        }
        setIsLoading(false); // Set loading to false when data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false); // Also set loading to false in case of error
      }
    });
    return () => {
      unsubscribe();
    };
  }, [isDeleteModal, user, pathname]);

  // const [blogId, setBlogId] = useState();

  const deleteBlogById = (id) => {
    const blogRef = ref(db, `blogsData/${id}`);
    remove(blogRef)
      .then(() => {
        console.log(`Blog with ID ${id} deleted successfully.`);
        // Optionally, you can update your local state here to remove the deleted blog
        // setblogsData((prevData) => prevData.filter((blog) => blog.id !== id));
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Error deleting blog:", error);
      });
  };

  const deleteModal = () => (
    <div className="delete-modal">
      <h3>Are you sure you want to permanently delete this?</h3>
      <div className="delete-btn" onClick={() => deleteBlogById(state.blogKey)}>
        Delete
      </div>
      <div className="cancel-btn" onClick={() => setIsDeleteModal(false)}>
        Cancel
      </div>
    </div>
  );

  return (
    <div className="Read">
      <div className="hero-section">
        <div
          className="gradient-overlay"
          style={{
            background: `linear-gradient(to bottom, ${color} , transparent)`,
          }}
        ></div>
        <div className="hero-content">
          <h1>{blogData?.title}:</h1>
          <h2>{blogData?.subTitle}</h2>

          <div className="author">
            <div
              className="author-profile"
              style={{ backgroundImage: `url(${blogData?.authorPhoto})` }}
            ></div>
            <p className="author-name">{blogData?.authorName}</p>
            <p className="article-date">{blogData?.date}</p>
          </div>
          <div className="hero-image-container">
            <div
              className="hero-image"
              style={{
                backgroundImage: `url(${
                  blogData?.heroImage
                    ? blogData?.heroImage
                    : blogData?.thumbnail
                })`,
              }}
            ></div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="p-content">
          {blogData?.blogContent &&
            Object.values(blogData?.blogContent)[0]?.map((item) => (
              <p key={item.key} style={styles[item.key]}>
                {item.text}
              </p>
            ))}
        </div>
      )}

      {user &&
        process.env.REACT_APP_AUTHORIZED_EMAILS.split(",").includes(
          user?.email
        ) && (
          <div className="action-btns">
            <Link
              to={`/edit/${blogData?.title}/${blogData?.blogKey}`}
              state={state}
              className="action-btn edit-btn"
            >
              <EditIcon className="icon" />
            </Link>
            <div
              onClick={() =>
                setIsDeleteModal((current) => (current = !current))
              }
              className="action-btn delete"
            >
              <DeleteIcon className="icon" />
            </div>
          </div>
        )}
      {isDeleteModal && deleteModal()}
    </div>
  );
}
