import React from "react";
import "./NavTab.css";

export default function NavTab({
  isAllActive,
  setIsAllActive,
  isGovernmentActive,
  setIsGovernmentActive,
  isEducationActive,
  setIsEducationActive,
  isDevelopmentActive,
  setIsDevelopmentActive,
}) {
  const tabNavigation = (toActivate) => {
    setIsAllActive(false);
    setIsGovernmentActive(false);
    setIsEducationActive(false);
    setIsDevelopmentActive(false);
    toActivate(true);
  };
  return (
    <div className="NavTab">
      <div
        onClick={() => tabNavigation(setIsAllActive)}
        className={`tab ${isAllActive && "active"}`}
      >
        All
      </div>
      <div
        onClick={() => tabNavigation(setIsGovernmentActive)}
        className={`tab ${isGovernmentActive && "active"}`}
      >
        Government
      </div>
      <div
        onClick={() => tabNavigation(setIsEducationActive)}
        className={`tab ${isEducationActive && "active"}`}
      >
        Education
      </div>
      <div
        onClick={() => tabNavigation(setIsDevelopmentActive)}
        className={`tab ${isDevelopmentActive && "active"}`}
      >
        Development
      </div>
    </div>
  );
}
