import { async } from "@firebase/util";
import { ref, update } from "firebase/database";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytesResumable,
} from "firebase/storage";
import { useEffect, useState } from "react";
import { db, storage } from "../../FirebaseConfig";
import "./EditAbout.css";

export default function EditAbout({ aboutTitle, aboutText, aboutImage }) {
  const [title, setTitle] = useState(aboutTitle || "");
  const [text, setText] = useState(aboutText || "");
  const [image, setImage] = useState(aboutImage || null);
  const [progress, setProgress] = useState(0);

  const [isUploading, setIsUploading] = useState(false);

  const handleFileChange = (e, setState) => {
    const file = e.target.files[0];
    if (file) {
      setState(URL.createObjectURL(file));
    } else {
      setState(null); // Handle the case where no file is selected
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsUploading(true);
      if (image.includes("https")) {
        const dataRef = ref(db, "about");

        const updates = {
          title: title,
          image: image,
          text: text,
        };

        await update(dataRef, updates);
        console.log("Data updated successfully");
        window.location.href = "/about";
      } else {
        await handlePhotoUpdate();
      }
    } catch (error) {
      setIsUploading(false);
      console.error("Error updating data:", error);
    }
  };

  const handlePhotoUpdate = async () => {
    try {
      const response = await fetch(image);
      const blob = await response.blob();
      const photoFileName = `about_image.jpg`;
      const photoRef = storageRef(storage, `about/${photoFileName}`);
      const uploadTask = uploadBytesResumable(photoRef, blob);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress_ = snapshot.bytesTransferred / snapshot.totalBytes;
          setProgress((progress_ * 100).toFixed());
        },
        (error) => {
          // handle error
          alert("Ooops, there was a problem uploading your photo");
          console.log(error);
          console.error("Upload error:", error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref)
            .then(async (downloadURL) => {
              const dataRef = ref(db, "about");

              const updates = {
                title: title,
                image: downloadURL,
                text: text,
              };

              await update(dataRef, updates)
                .then(() => {
                  // data updated successfully
                  window.location.href = "/about";
                  console.log("Photo uploaded successfully!");
                })
                .catch((error) => {
                  // Handle the error
                  setIsUploading(false);
                  alert("Error uploading photo");
                  console.error("Error uploading photo: ", error);
                });
            })
            .catch((error) => {
              setIsUploading(false);
              alert("Error getting download URL: ", error);
              console.error("Error getting download URL:", error);
            });
        }
      );
    } catch (error) {
      alert("Error updating photo:", error);
      console.error("Error updating photo:", error);
      setIsUploading(false);
    }
  };

  return (
    <form className="edit-about">
      <div className="input-group">
        <input
          type={"text"}
          value={title}
          className="input-title"
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>
      <div className="input-group">
        {image && (
          <img className="selected-image" alt="about-image" src={image} />
        )}
        <label className="custom-file-input">
          {image ? "Change Image" : "Pick an image"}
          <input
            type="file"
            accept="image/*"
            onChange={(e) => handleFileChange(e, setImage)}
            required
          />
        </label>
      </div>

      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        required
      ></textarea>
      <button
        className={isUploading ? "uploading" : "upload"}
        type="submit"
        onClick={(e) => handleSubmit(e)}
        disabled={isUploading}
      >
        {isUploading ? `Uploading ${progress}%...` : "Update"}
      </button>
    </form>
  );
}
