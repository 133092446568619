import AsyncStorage from "@react-native-async-storage/async-storage";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { ref, set } from "firebase/database";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../FirebaseConfig";
import { login } from "../../redux/features/userSlice";
import "./Signin.css";

export default function Signin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUsername] = useState("");

  const inputValidation = () => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

    if (email.length > 0) {
      if (reg.test(email)) {
        if (password.length > 7) {
          if (password === confirmPassword) {
            if (username.length > 0) {
              return true;
            } else {
              alert("Input your username!!");
              return false;
            }
          } else {
            alert("Your password doesn't match");
            return false;
          }
        } else {
          alert("Weak Password!!");
          return false;
        }
      } else {
        alert("invalid email");
        return false;
      }
    } else {
      alert("Please Input your Email!!");
      return false;
    }
  };

  const [isSigningIn, setIsSigningIn] = useState(false);

  const onSignInUser = () => {
    if (inputValidation()) {
      setIsSigningIn(true);
      createUserWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // Signed up
          const user = userCredential.user;
          if (user) {
            const userRef = ref(db, "users/" + user?.uid);
            await AsyncStorage.setItem("@user", JSON.stringify(user));
            set(userRef, {
              displayName: `${username}  `,
              uid: user?.uid,
              email: email,
            });
            dispatch(
              login({
                displayName: `${username}  `,
                uid: user?.uid,
                email: email,
              })
            );
          }
          // ...
          navigate("/");
        })
        .catch((error) => {
          setIsSigningIn(false);

          const errorCode = error.code; // Move this line above the if statement

          if (errorCode === "auth/email-already-in-use") {
            alert(
              "The email address is already in use. Please try Logging in instead."
            );
          } else {
            alert(error.message); // Display other error messages to the user
          }
        });
    }
  };
  return (
    <div className="Signin">
      <div className="form">
        <div className="input-div">
          <input
            type={"text"}
            placeholder="Your Full Name"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type={"email"}
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="input-div">
          <input
            type={"password"}
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="input-div">
          <input
            type={"password"}
            placeholder="Confirm your password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </div>
        <button className="submit-btn" onClick={() => onSignInUser()}>
          {isSigningIn ? "Signing in..." : "Sign in"}
        </button>
      </div>
    </div>
  );
}
