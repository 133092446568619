import AsyncStorage from "@react-native-async-storage/async-storage";
import { signInWithEmailAndPassword } from "firebase/auth";
import { onValue, ref } from "firebase/database";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../FirebaseConfig";
import { login } from "../../redux/features/userSlice";
import "./Login.css";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoginIn, setIsLoginIn] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const inputValidation = () => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    if (email.length > 0) {
      setEmail((prev) => (prev = email.replace(/\./g, "")));
      if (reg.test(email)) {
        if (password.length > 7) {
          return true;
        } else {
          alert("Weak Password!!");
          return false;
        }
      } else {
        alert("invalid email");
        return false;
      }
    } else {
      alert("Please Input your Email!!");
      return false;
    }
  };

  const onLonginUser = () => {
    if (inputValidation()) {
      setIsLoginIn(true);
      signInWithEmailAndPassword(auth, email, password)
        .then(async (userCredential) => {
          // Signed in
          const user = userCredential.user;

          if (user) {
            const userRef = ref(db, "users/" + user?.uid);
            await AsyncStorage.setItem("@user", JSON.stringify(user));
            onValue(userRef, (snapshot) => {
              const userData = snapshot?.val();
              if (userData) {
                dispatch(
                  login({
                    displayName: userData.displayName || "",
                    email: userData.email,
                    uid: userData.uid,
                  })
                );
              }
            });

            // navigate to home page
            navigate("/");
          }
        })
        .catch((error) => {
          setIsLoginIn(false);
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorCode + ": " + errorMessage);
        });
    }
  };
  return (
    <div className="Login">
      <div className="form">
        <div className="input-div">
          <input
            type={"email"}
            placeholder="Enter your email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>
        <div className="input-div">
          <input
            type={"password"}
            placeholder="Enter your password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </div>
        <button className="submit-btn" onClick={() => onLonginUser()}>
          {isLoginIn ? "Logging..." : "Login"}
        </button>
      </div>
    </div>
  );
}
