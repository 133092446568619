import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default function Footer() {
  return (
    <div className="footer">
      <div className="fast-links">
        <div className="logo">
          <h1>ILLUMINATING AFRICA</h1>
        </div>
        <div className="links">
          <Link className="link">Concept</Link>
          <Link className="link">Our Story</Link>
          <Link className="link">Blogs</Link>
          <Link className="link">Careers</Link>
          <Link className="link">Contact us</Link>
        </div>
        <div className="social-media">
          <Link className="link">
            <FacebookIcon />
          </Link>
          <Link className="link">
            <InstagramIcon />
          </Link>
          <Link className="link">
            <TwitterIcon />
          </Link>
          <Link className="link">
            <LinkedInIcon />
          </Link>
        </div>
      </div>
      <div className="credentials">
        <Link className="link">Privacy Policy</Link>
        <Link className="link">Term Service</Link>
        <Link className="link">Coockies Settings</Link>
      </div>
    </div>
  );
}
