import Intro from "../../sections/about/Intro";
import "./About.css";

export default function About() {
  return (
    <div className="About">
      <Intro />
    </div>
  );
}
