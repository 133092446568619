import "./Loading.css";

export default function Loading() {
  return (
    <div className="loading">
      <div className="image"></div>
      <div className="content">
        <div className="stamp">
          <div className="category">The only best category</div>
          <div className="reading-time">readTime min</div>
        </div>
        <h2 className="title">This is the most epic title</h2>
        <p className="description">
          everything you need to know about life is here in illumating africa.
          we illuminate the unillumated parst
        </p>
        <div className="read-btn">
          <div className="link">Read Now</div>
        </div>
      </div>
    </div>
  );
}
