import React from "react";
import "./Contact.css";

export default function Contact() {
  return (
    <div className="Contact">
      <h2>Let's talk</h2>
      <p className="">info@cyril-blog.com</p>

      <form>
        <div className="input-row names">
          <div className="input-div firstname">
            <label>YOUR FIRST NAME</label>
            <br />
            <input placeholder="i.e John" />
          </div>
          <div className="input-div secondname">
            <label>YOUR Last NAME</label>
            <br />

            <input placeholder="i.e Doe" />
          </div>
        </div>
        <div className="input-row  address">
          <div className="input-div email-address">
            <label>YOUR email</label>
            <br />

            <input placeholder="i.e johndoe@gmail.com" />
          </div>
          <div className="input-div phone-address">
            <label>YOUR PHONE NUMBER</label>
            <br />

            <input placeholder="plus the country code. e.g +2547123456789" />
          </div>
        </div>
        <div className="message">
          <label>YOur Message</label>
          <br />

          <textarea placeholder="Please enter do it too"></textarea>
        </div>
        <input type={"submit"} className="submit-btn" />
      </form>
    </div>
  );
}
