import { onValue, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../../components/loading/Loading";
import { db } from "../../FirebaseConfig";
import "./Featured.css";
import FeaturedView from "./FeaturedView";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/features/userSlice";

export default function Featured() {
  // const featuredData = [
  //   {
  //     category: "inspirational",
  //     description:
  //       "The Inspirational Corner showcases individual stories of people making a positive impact on Africa through unique projects and initiatives. These uplifting narratives celebrate the resilience, creativity, and determination of individuals contributing to the advancement of the continent.",
  //     main: {
  //       title: "The reason why tech users love apple products!",
  //       thumbnail:
  //         "https://images.pexels.com/photos/3889927/pexels-photo-3889927.jpeg?auto=compress&cs=tinysrgb&w=800",
  //       author: { name: "John Doe", photo: "https://i.pravatar.cc/300" },
  //       date: "6th November 2017",
  //       tag: "Tech",
  //     },
  //     blogs: [
  //       {
  //         title: "The reason why tech users love apple products!",
  //         thumbnail:
  //           "https://images.pexels.com/photos/3889927/pexels-photo-3889927.jpeg?auto=compress&cs=tinysrgb&w=800",
  //         author: { name: "John Doe", photo: "https://i.pravatar.cc/300" },
  //         date: "6th November 2017",
  //         tag: "Tech",
  //       },
  //       {
  //         title: "The reason why tech users love apple products!",
  //         thumbnail:
  //           "https://images.pexels.com/photos/3889927/pexels-photo-3889927.jpeg?auto=compress&cs=tinysrgb&w=800",
  //         author: { name: "John Doe", photo: "https://i.pravatar.cc/300" },
  //         date: "6th November 2017",
  //         tag: "Tech",
  //       },
  //       {
  //         title: "The reason why tech users love apple products!",
  //         thumbnail:
  //           "https://images.pexels.com/photos/3889927/pexels-photo-3889927.jpeg?auto=compress&cs=tinysrgb&w=800",
  //         author: { name: "John Doe", photo: "https://i.pravatar.cc/300" },
  //         date: "6th November 2017",
  //         tag: "Tech",
  //       },
  //       {
  //         title: "The reason why tech users love apple products!",
  //         thumbnail:
  //           "https://images.pexels.com/photos/3889927/pexels-photo-3889927.jpeg?auto=compress&cs=tinysrgb&w=800",
  //         author: { name: "John Doe", photo: "https://i.pravatar.cc/300" },
  //         date: "6th November 2017",
  //         tag: "Tech",
  //       },
  //       {
  //         title: "The reason why tech users love apple products!",
  //         thumbnail:
  //           "https://images.pexels.com/photos/3889927/pexels-photo-3889927.jpeg?auto=compress&cs=tinysrgb&w=800",
  //         author: { name: "John Doe", photo: "https://i.pravatar.cc/300" },
  //         date: "6th November 2017",
  //         tag: "Tech",
  //       },
  //       {
  //         title: "The reason why tech users love apple products!",
  //         thumbnail:
  //           "https://images.pexels.com/photos/3889927/pexels-photo-3889927.jpeg?auto=compress&cs=tinysrgb&w=800",
  //         author: { name: "John Doe", photo: "https://i.pravatar.cc/300" },
  //         date: "6th November 2017",
  //         tag: "Tech",
  //       },
  //       {
  //         title: "The reason why tech users love apple products!",
  //         thumbnail:
  //           "https://images.pexels.com/photos/3889927/pexels-photo-3889927.jpeg?auto=compress&cs=tinysrgb&w=800",
  //         author: { name: "John Doe", photo: "https://i.pravatar.cc/300" },
  //         date: "6th November 2017",
  //         tag: "Tech",
  //       },
  //     ],
  //   },

  //   {
  //     category: "Column",
  //     description:
  //       "Our column section features diverse writers united by a common goal—to illuminate Africa's path. Through their varied perspectives, we bring you rich and varied content that adds depth to the narrative of progress in Africa.",
  //     main: {
  //       title: "The reason why tech users love apple products!",
  //       thumbnail:
  //         "https://images.pexels.com/photos/3889927/pexels-photo-3889927.jpeg?auto=compress&cs=tinysrgb&w=800",
  //       author: { name: "John Doe", photo: "https://i.pravatar.cc/300" },
  //       date: "6th November 2017",
  //       tag: "Tech",
  //     },
  //     blogs: [
  //       {
  //         title: "The reason why tech users love apple products!",
  //         thumbnail:
  //           "https://images.pexels.com/photos/3889927/pexels-photo-3889927.jpeg?auto=compress&cs=tinysrgb&w=800",
  //         author: { name: "John Doe", photo: "https://i.pravatar.cc/300" },
  //         date: "6th November 2017",
  //         tag: "Tech",
  //       },
  //       {
  //         title: "The reason why tech users love apple products!",
  //         thumbnail:
  //           "https://images.pexels.com/photos/3889927/pexels-photo-3889927.jpeg?auto=compress&cs=tinysrgb&w=800",
  //         author: { name: "John Doe", photo: "https://i.pravatar.cc/300" },
  //         date: "6th November 2017",
  //         tag: "Tech",
  //       },
  //       {
  //         title: "The reason why tech users love apple products!",
  //         thumbnail:
  //           "https://images.pexels.com/photos/3889927/pexels-photo-3889927.jpeg?auto=compress&cs=tinysrgb&w=800",
  //         author: { name: "John Doe", photo: "https://i.pravatar.cc/300" },
  //         date: "6th November 2017",
  //         tag: "Tech",
  //       },
  //       {
  //         title: "The reason why tech users love apple products!",
  //         thumbnail:
  //           "https://images.pexels.com/photos/3889927/pexels-photo-3889927.jpeg?auto=compress&cs=tinysrgb&w=800",
  //         author: { name: "John Doe", photo: "https://i.pravatar.cc/300" },
  //         date: "6th November 2017",
  //         tag: "Tech",
  //       },
  //       {
  //         title: "The reason why tech users love apple products!",
  //         thumbnail:
  //           "https://images.pexels.com/photos/3889927/pexels-photo-3889927.jpeg?auto=compress&cs=tinysrgb&w=800",
  //         author: { name: "John Doe", photo: "https://i.pravatar.cc/300" },
  //         date: "6th November 2017",
  //         tag: "Tech",
  //       },
  //       {
  //         title: "The reason why tech users love apple products!",
  //         thumbnail:
  //           "https://images.pexels.com/photos/3889927/pexels-photo-3889927.jpeg?auto=compress&cs=tinysrgb&w=800",
  //         author: { name: "John Doe", photo: "https://i.pravatar.cc/300" },
  //         date: "6th November 2017",
  //         tag: "Tech",
  //       },
  //       {
  //         title: "The reason why tech users love apple products!",
  //         thumbnail:
  //           "https://images.pexels.com/photos/3889927/pexels-photo-3889927.jpeg?auto=compress&cs=tinysrgb&w=800",
  //         author: { name: "John Doe", photo: "https://i.pravatar.cc/300" },
  //         date: "6th November 2017",
  //         tag: "Tech",
  //       },
  //     ],
  //   },
  // ];

  const [featuredData, setfeaturedData] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
    // Subscribe to real-time updates
    const starCountRef = ref(db, "featuredData");
    const unsubscribe = onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setfeaturedData(Object.values(data));
        // console.log(Object.values(data));
      }
    });
    // Cleanup the subscription on component unmount
    return () => {
      unsubscribe();
    };
  }, []);

  const user = useSelector(selectUser);
  const [isEditable, setIsEditable] = useState(false);
  useEffect(() => {
    setIsEditable(
      process.env.REACT_APP_AUTHORIZED_EMAILS.split(",").includes(user?.email)
    );
  }, []);

  if (featuredData.length === 0) {
    return (
      <div className="featured">
        <Loading />
        {user && isEditable && (
          <Link to={"/create"} className="create-btn" style={{ zIndex: 23 }}>
            <AddCircleOutlineIcon className="create-btn-icon" />
          </Link>
        )}
      </div>
    );
  } else {
    return (
      <div className="featured">
        {featuredData.map((item, index) => (
          <FeaturedView key={index} data={item} index={index} />
        ))}
      </div>
    );
  }
}
