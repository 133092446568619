import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { logout, selectUser } from "../../redux/features/userSlice";
import { signOut } from "firebase/auth";
import { auth } from "../../FirebaseConfig";
import logo from "../../assets/illuminating_africa_logo_no_bg.png";

export default function Header() {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const [isMobileView, setIsMobileView] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(false);
  useEffect(() => {
    window.innerWidth < 993 ? setIsMobileView(true) : setIsMobileView(false);
    window.addEventListener("resize", () => {
      window.innerWidth < 993 ? setIsMobileView(true) : setIsMobileView(false);
    });
  }, []);
  return (
    <div className="Header">
      <Link to="/" onClick={() => setIsMenuActive(false)} className="logo link">
        {isMobileView ? (
          <img src={logo} width={"60px"} />
        ) : (
          <h1>Illuminating Africa</h1>
        )}
      </Link>
      {isMobileView ? (
        <>
          <MenuIcon
            className="menu-icon"
            onClick={() => setIsMenuActive((current) => (current = !current))}
          />
          {isMenuActive && (
            <nav
              className={`${isMenuActive && "mobile-nav"}`}
              onClick={() => setIsMenuActive(false)}
            >
              <div
                onClick={() => setIsMenuActive(false)}
                className={`nav-option`}
              >
                <Link className="nav-option-link link" to="/">
                  Home
                </Link>
              </div>
              <div
                onClick={() => setIsMenuActive(false)}
                className="nav-option"
              >
                <Link className="nav-option-link link" to="/featured">
                  Featured
                </Link>
              </div>
              <div
                onClick={() => setIsMenuActive(false)}
                className="nav-option"
              >
                <Link className="nav-option-link link" to="/about">
                  About
                </Link>
              </div>
              {/* <div
                onClick={() => setIsMenuActive(false)}
                className="nav-option"
              >
                <Link className="nav-option-link link" to="/contact">
                  Contact
                </Link>
              </div> */}
            </nav>
          )}
        </>
      ) : (
        <nav>
          <div className={`nav-option`}>
            <Link className="nav-option-link link" to="/">
              Home
            </Link>
          </div>
          <div className="nav-option">
            <Link className="nav-option-link link" to="/featured">
              Featured
            </Link>
          </div>
          <div className="nav-option">
            <Link className="nav-option-link link" to="/about">
              About
            </Link>
          </div>
          {/* <div className="nav-option">
            <Link className="nav-option-link link" to="/contact">
              Contact
            </Link>
          </div> */}
        </nav>
      )}
      <div className="signups">
        {!user ? (
          <>
            <div className="login">
              <Link className="link" to="/login">
                Login
              </Link>
            </div>
            <div className="signin">
              <Link className="link" to="/signin">
                Sign in
              </Link>
            </div>
          </>
        ) : (
          <div className="user-profile">
            <p className="user-name">{user.displayName}</p>
            <p
              className="logout"
              onClick={() => {
                signOut(auth)
                  .then(() => {
                    dispatch(logout());
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }}
            >
              Logout
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
