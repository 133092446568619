import React, { useEffect, useState } from "react";
import "./Edit.css";
import { db, storage } from "../../FirebaseConfig";
import { ref, push, child, set, update } from "firebase/database";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytesResumable,
} from "firebase/storage";
import { format } from "date-fns";

import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation } from "react-router-dom";

export default function Edit() {
  const location = useLocation();
  const { state } = location;

  const [styles, setStyles] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (state.blogContent) {
      Object.values(state.blogContent)[0].forEach((item) => {
        // Handle inline styles
        if (item.inlineStyleRanges) {
          item.inlineStyleRanges.forEach((range) => {
            switch (range.style) {
              case "BOLD":
                setStyles((prevStyles) => ({
                  ...prevStyles,
                  [item.key]: { fontWeight: "bold", ...prevStyles[item.key] },
                }));
                break;
              case "UNDERLINE":
                setStyles((prevStyles) => ({
                  ...prevStyles,
                  [item.key]: {
                    textDecoration: "underline",
                    ...prevStyles[item.key],
                  },
                }));
                break;
              case "ITALIC":
                setStyles((prevStyles) => ({
                  ...prevStyles,
                  [item.key]: { fontStyle: "italic", ...prevStyles[item.key] },
                }));
                break;
              // Add cases for other inline styles as needed
              default:
                break;
            }
          });
        }

        // Handle block types
        switch (item.type) {
          case "header-one":
            setStyles((prevStyles) => ({
              ...prevStyles,
              [item.key]: { fontSize: "32px", ...prevStyles[item.key] },
            }));
            break;
          case "header-two":
            setStyles((prevStyles) => ({
              ...prevStyles,
              [item.key]: { fontSize: "24px", ...prevStyles[item.key] },
            }));
            break;
          case "unordered-list-item":
            setStyles((prevStyles) => ({
              ...prevStyles,
              [item.key]: { listStyleType: "disc", ...prevStyles[item.key] },
            }));
            break;
          case "ordered-list-item":
            setStyles((prevStyles) => ({
              ...prevStyles,
              [item.key]: { listStyleType: "decimal", ...prevStyles[item.key] },
            }));
            break;
          // Add cases for other block types as needed
          default:
            break;
        }

        // Handle other possible stylings
        // For example:
        // if (range.style === "COLOR") {
        //   setStyles((prevStyles) => ({
        //     ...prevStyles,
        //     [item.key]: { color: range.color, ...prevStyles[item.key] },
        //   }));
        // }
      });
    }
  }, [state.blogContent]);

  const [title, setTitle] = useState(state.title || "");
  const [subTitle, setSubTitle] = useState(state.subTitle || "");
  const [synopsis, setSynopsis] = useState(state.synopsis || "");
  const [category, setCategory] = useState(state.category || "");
  const [authorName, setAuthorName] = useState(state.authorName || "");
  const [authorPhoto, setAuthorPhoto] = useState(state.authorPhoto || "");
  const [heroImage, setHeroImage] = useState(state.heroImage || null);
  const [thumbnail, setThumbnail] = useState(state.thumbnail || null);
  const [isPinned, setIsPinned] = useState(false);
  const [readTime, setReadTime] = useState(state.readTime || "");
  const [isPosting, setIsPosting] = useState(false);
  const [editorState, setEditorState] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState(0);

  const handleFileChange = (e, setState) => {
    const file = e.target.files[0];
    if (file) {
      setState(URL.createObjectURL(file));
    } else {
      setState(null); // Handle the case where no file is selected
    }
  };

  const onPost = async () => {
    setIsPosting(true);
    // Check if all required fields are filled
    if (
      !title ||
      !subTitle ||
      !synopsis ||
      !category ||
      !thumbnail ||
      !authorName ||
      !authorPhoto ||
      !readTime
    ) {
      alert("Please fill in all fields.");
      setIsPosting(false);

      return;
    }

    // if (
    //   title != state.title ||
    //   subTitle != state.subTitle ||
    //   synopsis != state.synopsis ||
    //   category != state.category ||
    //   thumbnail != state.thumbnail ||
    //   authorName != state.authorName ||
    //   authorPhoto != state.authorPhoto ||
    //   content != state.blogContent ||
    //   readTime != state.readTime
    // ) {
    console.log("readTime");

    // Check if at least one paragraph is provided
    if (Object.values(content)[0] === 0) {
      alert("Please provide at least one paragraph.");
      setIsPosting(false);

      return;
    }

    try {
      const currentDate = format(new Date(), "MMMM dd, yyyy");

      let authorPhotoUrl = null;
      let thumbnailUrl = null;
      let heroImageUrl = null;
      // Upload images to Firebase Storage and get download URLs
      if (authorPhoto !== state.authorPhoto) {
        authorPhotoUrl = await uploadImageToStorage(
          authorPhoto,
          "authorPhotos",
          `${Date.now()}_${authorName.replace(/ /g, "")}`
        );
      } else {
        authorPhotoUrl = state.authorPhoto;
      }
      if (thumbnail !== state.thumbnail) {
        thumbnailUrl = await uploadImageToStorage(
          thumbnail,
          "thumbnails",
          `${Date.now()}_${title.replace(/ /g, "")}`
        );
      } else {
        thumbnailUrl = state.thumbnail;
      }
      if (heroImage !== state.heroImage) {
        heroImageUrl = await uploadImageToStorage(
          heroImage,
          "heroImages",
          `${Date.now()}_${title.replace(/ /g, "")}`
        );
      } else {
        heroImageUrl = state.heroImage;
      }

      // Store blog data in Firebase Realtime Database
      const postRef = ref(db, `blogsData/${state.blogKey}`);
      const data = {
        title: title,
        subTitle: subTitle,
        description: synopsis,
        category: category,
        thumbnail: thumbnailUrl,
        heroImage: heroImageUrl,
        blogContent: content,
        authorName: authorName,
        authorPhoto: authorPhotoUrl,
        date: currentDate,
        isPinned: isPinned,
        readTime: readTime,
      };
      await set(postRef, data);

      // Clear all fields after successful upload
      setTitle("");
      setSubTitle("");
      setSynopsis("");
      setCategory("");
      setThumbnail("");
      setHeroImage("");
      setAuthorName("");
      setAuthorPhoto("");
      setIsPinned(false);
      setReadTime("");

      // Navigate to home page if needed
      // navigate("/");
      window.location.href = "/";
    } catch (error) {
      console.error("Error uploading data:", error);
      alert("An error occurred while posting. Please try again later.");
    } finally {
      setIsPosting(false);
    }
    // } else {
    //   setIsPosting(false);
    // }

    const uploadImageToStorage = async (image, folder, imageName) => {
      if (!image || !image.startsWith("blob")) return ""; // If no image or not a blob URL, return empty string

      try {
        const response = await fetch(image);
        const blob = await response.blob();

        const imageRef = storageRef(storage, `${folder}/${imageName}`);
        const uploadTask = uploadBytesResumable(imageRef, blob);

        // Create a Promise to await getDownloadURL
        const downloadUrlPromise = new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress_ = snapshot.bytesTransferred / snapshot.totalBytes;
              setUploadingProgress((progress_ * 100).toFixed());
            },
            (error) => {
              // handle error
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref)
                .then((downloadURL) => {
                  resolve(downloadURL);
                })
                .catch((error) => {
                  reject(error);
                });
            }
          );
        });

        // Wait for the download URL and return it
        return await downloadUrlPromise;
      } catch (error) {
        console.error("Error uploading image:", error);
        throw error;
      }
    };
  };

  const [content, setContent] = useState(state.blogContent);
  const updateParagraphs = (index, newText) => {
    const updatedContent = { ...content };
    Object.values(updatedContent)[0][index].text = newText;
    setContent(updatedContent);
  };
  return (
    <div className="edit">
      <div className="edit-content">
        <input
          type={"text"}
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <input
          type={"text"}
          placeholder="Subtitle"
          value={subTitle}
          onChange={(e) => setSubTitle(e.target.value)}
        />
        <textarea
          className="synopsis"
          placeholder="Synopsis"
          value={synopsis}
          onChange={(e) => setSynopsis(e.target.value)}
        ></textarea>
        <h3>Categories</h3>
        <div className="categories">
          <div className="category">
            <input
              type={"radio"}
              value="Governance"
              checked={category === "Governance"}
              onChange={() => setCategory("Governance")}
            />
            <label>Governance</label>
          </div>
          <div className="category">
            <input
              type={"radio"}
              value="Development"
              checked={category === "Development"}
              onChange={() => setCategory("Development")}
            />
            <label>Development</label>
          </div>
          <div className="category">
            <input
              type={"radio"}
              value="Education"
              checked={category === "Education"}
              onChange={() => setCategory("Education")}
            />
            <label>Education</label>
          </div>
        </div>

        <div className="images">
          <div className="image">
            <p>Select a thumbnail image</p>
            {thumbnail && <img src={thumbnail} />}
            <input
              type="file"
              placeholder="Thumbnail image"
              onChange={(e) => handleFileChange(e, setThumbnail)}
            />
          </div>
          <div className="image">
            <p>Select a hero image</p>
            {heroImage && <img src={heroImage} />}

            <input
              type="file"
              placeholder="Hero image"
              onChange={(e) => handleFileChange(e, setHeroImage)}
            />
          </div>
        </div>
        {/* {console.log(state.blogContent)} */}

        <div className="p-content">
          {Object.values(content)[0].map((item, index) => (
            <div>
              {item.text.length > 0 && (
                <textarea
                  key={item.key}
                  style={{ width: "70vw", minHeight: "10vh", padding: 10 }}
                  value={item.text}
                  onChange={(e) => updateParagraphs(index, e.target.value)}
                ></textarea>
              )}
            </div>
          ))}
        </div>

        <div className="meta-data">
          <div>
            <h3>What will be the read time</h3>
            <input
              className="read-time"
              type="number"
              placeholder="Read time in minutes"
              value={readTime}
              onChange={(e) => setReadTime(e.target.value)}
            />
          </div>

          <div className="pinning">
            <h3>Will this blog be pinned at the main</h3>
            <div className="pins">
              <div className="pin">
                <input
                  type="radio"
                  value="yes"
                  checked={isPinned}
                  onChange={() => setIsPinned(true)}
                />
                <label>Pinned</label>
              </div>
              <div className="pin">
                <input
                  type="radio"
                  value="no"
                  checked={!isPinned}
                  onChange={() => setIsPinned(false)}
                />
                <label>Not Pinned</label>
              </div>
            </div>
          </div>
        </div>
        <div className="author-details">
          <input
            type={"text"}
            placeholder="Author's Name"
            value={authorName}
            onChange={(e) => setAuthorName(e.target.value)}
          />
          <div>
            <p>Select author's photo</p>
            {authorPhoto && <img src={authorPhoto} className="profile-photo" />}
            <input
              type="file"
              placeholder="Author's photo"
              onChange={(e) =>
                setAuthorPhoto(URL.createObjectURL(e.target.files[0]))
              }
            />
          </div>
        </div>
        <button
          className="submit-btn"
          onClick={() => onPost()}
          style={isPosting ? { backgroundColor: "black", color: "white" } : {}}
        >
          {isPosting ? `Posting ${uploadingProgress}%...` : "Post"}
        </button>
      </div>
    </div>
  );
}
