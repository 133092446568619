import React, { lazy, Suspense, useEffect, useState } from "react";
import "./Home.css";
import NavTab from "../../sections/home/NavTab";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Link } from "react-router-dom";
import { onValue, ref } from "firebase/database";
import { db } from "../../FirebaseConfig";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/features/userSlice";
import Loading from "../../components/loading/Loading";

const MainCard = lazy(() => import("../../components/maincard/MainCard"));
const Card = lazy(() => import("../../components/card/Card"));

export default function Home() {
  const user = useSelector(selectUser);
  const [isAllActive, setIsAllActive] = useState(true);
  const [isGovernmentActive, setIsGovernmentActive] = useState(false);
  const [isEducationActive, setIsEducationActive] = useState(false);
  const [isDevelopmentActive, setIsDevelopmentActive] = useState(false);
  const [blogsData, setblogsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    setIsEditable(
      process.env.REACT_APP_AUTHORIZED_EMAILS.split(",").includes(user?.email)
    );
    // console.log(process.env.REACT_APP_AUTHORIZED_EMAILS.split(",").includes(user?.email));
    window.scrollTo(0, 0);
    const starCountRef = ref(db, "blogsData");
    const unsubscribe = onValue(starCountRef, (snapshot) => {
      try {
        const data = snapshot.val();
        if (data) {
          setblogsData(data);
          // setblogsData(Object.values(data).reverse());
          setIsLoading(false); // Set loading to false once data is fetched
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error accordingly
      }
    });
    return () => {
      unsubscribe();
    };
  }, [isEditable, user]);

  if (isLoading) {
    return (
      <div className="Home">
        <Loading />
        {user && isEditable && (
          <Link to={"/create"} className="create-btn" style={{ zIndex: 23 }}>
            <AddCircleOutlineIcon className="create-btn-icon" />
          </Link>
        )}
      </div>
    );
  }

  return (
    <div className="Home">
      {/* {Object.values(blogsData).map((item) =>
        // console.log(Object.values(blogsData).indexOf(item))
        console.log(
          Object.keys(blogsData)[Object.values(blogsData).indexOf(item)]
        )
      )} */}
      {/* <Billboard /> */}
      {Object.values(blogsData).map(
        (
          {
            title,
            subTitle,
            category,
            readTime,
            isPinned,
            thumbnail,
            heroImage,
            authorPhoto,
            authorName,
            date,
            blogContent,
            description,
          },
          index
        ) => {
          return (
            isPinned && (
              <Suspense
                key={title}
                fallback={<div>Main loading please wait...</div>}
              >
                <MainCard
                  title={title}
                  readTime={readTime}
                  description={description}
                  thumbnail={thumbnail}
                  category={category}
                  blogData={{
                    date: date,
                    blogKey: Object.keys(blogsData)[index],
                    title: title,
                    thumbnail: thumbnail,
                    category: category,
                    readTime: readTime,
                    subTitle: subTitle,
                    heroImage: heroImage,
                    authorName: authorName,
                    authorPhoto: authorPhoto,
                    description: description,
                    blogContent: blogContent,
                  }}
                />
              </Suspense>
            )
          );
        }
      )}
      <NavTab
        isAllActive={isAllActive}
        setIsAllActive={setIsAllActive}
        isGovernmentActive={isGovernmentActive}
        setIsGovernmentActive={setIsGovernmentActive}
        isEducationActive={isEducationActive}
        setIsEducationActive={setIsEducationActive}
        isDevelopmentActive={isDevelopmentActive}
        setIsDevelopmentActive={setIsDevelopmentActive}
      />
      {isGovernmentActive && (
        <p>
          Africa grapples with significant governance challenges, including
          corruption, coup d&#39;états, insurgent militias, and poverty. Despite
          these obstacles, some governments demonstrate commendable efforts to
          overcome hindrances to good governance. Our multidimensional media
          project aims to raise awareness about the essential characteristics of
          good governance and emphasizes the role of democracy in achieving
          sustainable progress in Africa. We also explore the responsibilities
          of government officials and the active role citizens play in the
          governance process.
        </p>
      )}
      {isEducationActive && (
        <p>
          Education is a pivotal driver for knowledge growth and innovation in
          Africa, contributing significantly to the continent&#39;s progress.
          While literacy levels may be lower compared to other continents, many
          African governments have implemented policies to elevate education
          standards. Illuminating Africa delves into the dynamics of education
          across different countries, sharing unique individual educational
          journeys within the continent.
        </p>
      )}
      {isDevelopmentActive && (
        <p>
          Development remains a central focus in discussions about Africa, with
          international rankings often categorizing most African countries as
          developing. Facilitating development requires collaborative efforts
          from both government and private sectors. Illuminating Africa actively
          contributes to citizen awareness, fostering conversations that empower
          individuals to take ownership of development projects and hold
          governments accountable. Our discussions explore various development
          strategies and highlight ongoing projects shaping the continent&#39;s
          future.
        </p>
      )}

      <div className="blogs">
        {Object.values(blogsData).map(
          (
            {
              title,
              subTitle,
              category,
              readTime,
              thumbnail,
              heroImage,
              authorPhoto,
              authorName,
              date,
              blogContent,
              description,
            },
            index
          ) => {
            if (isAllActive) {
              return (
                <Suspense
                  key={title}
                  fallback={<div>Component1 are loading please wait...</div>}
                >
                  <Card
                    title={title}
                    readTime={readTime}
                    description={description}
                    thumbnail={thumbnail}
                    category={category}
                    blogData={{
                      date: date,
                      blogKey: Object.keys(blogsData)[index],

                      title: title,
                      thumbnail: thumbnail,
                      category: category,
                      subTitle: subTitle,
                      heroImage: heroImage,
                      blogContent: blogContent,
                      readTime: readTime,
                      authorName: authorName,
                      description: description,
                      authorPhoto: authorPhoto,
                    }}
                  />
                </Suspense>
              );
            } else if (isGovernmentActive) {
              return (
                category === "Governance" && (
                  <Suspense
                    key={title}
                    fallback={<div>Component1 are loading please wait...</div>}
                  >
                    <Card
                      title={title}
                      readTime={readTime}
                      description={description}
                      thumbnail={thumbnail}
                      category={category}
                      blogData={{
                        date: date,
                        title: title,
                        blogKey: Object.keys(blogsData)[index],

                        thumbnail: thumbnail,
                        category: category,
                        subTitle: subTitle,
                        readTime: readTime,
                        heroImage: heroImage,
                        authorName: authorName,
                        description: description,
                        authorPhoto: authorPhoto,
                        blogContent: blogContent,
                      }}
                    />
                  </Suspense>
                )
              );
            } else if (isEducationActive) {
              return (
                category === "Education" && (
                  <Suspense
                    key={title}
                    fallback={<div>Component1 are loading please wait...</div>}
                  >
                    <Card
                      title={title}
                      readTime={readTime}
                      description={description}
                      thumbnail={thumbnail}
                      category={category}
                      blogData={{
                        date: date,
                        blogKey: Object.keys(blogsData)[index],
                        title: title,
                        subTitle: subTitle,
                        thumbnail: thumbnail,
                        category: category,
                        readTime: readTime,
                        heroImage: heroImage,
                        authorName: authorName,
                        authorPhoto: authorPhoto,
                        description: description,
                        blogContent: blogContent,
                      }}
                    />
                  </Suspense>
                )
              );
            } else if (isDevelopmentActive) {
              return (
                category === "Development" && (
                  <Suspense
                    key={title}
                    fallback={<div>Component1 are loading please wait...</div>}
                  >
                    <Card
                      title={title}
                      readTime={readTime}
                      description={description}
                      thumbnail={thumbnail}
                      category={category}
                      blogData={{
                        date: date,
                        title: title,
                        subTitle: subTitle,
                        description: description,
                        readTime: readTime,
                        thumbnail: thumbnail,
                        category: category,
                        heroImage: heroImage,
                        authorName: authorName,
                        authorPhoto: authorPhoto,
                        blogContent: blogContent,
                      }}
                    />
                  </Suspense>
                )
              );
            }
          }
        )}
      </div>
      {user && isEditable && (
        <Link to={"/create"} className="create-btn" style={{ zIndex: 23 }}>
          <AddCircleOutlineIcon className="create-btn-icon" />
        </Link>
      )}
    </div>
  );
}
