import React from "react";
import "./FeaturedCard.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

export default function FeaturedCard({ title, thumbnail, author, date, tag }) {
  return (
    <div className="featured-card">
      <div
        className="featured-card-img"
        style={{ backgroundImage: `url(${thumbnail})` }}
      ></div>
      <div className="featured-card-details">
        <div className="tag">{tag}</div>
        <h3>{title}</h3>
        <div className="acknowledgements">
          <div className="right">
            <img alt="author" className="author-pic" src={author.photo} />
            <p>by</p>
            <h3>{author.name}</h3>
          </div>
          <div className="left">
            <AccessTimeIcon className="icon" />
            <p>{date}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
