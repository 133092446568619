import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/features/userSlice";
import "./Intro.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditAbout from "./EditAbout";
import { onValue, ref } from "firebase/database";
import { db } from "../../FirebaseConfig";

export default function Intro() {
  const user = useSelector(selectUser);
  const [isEditable, setIsEditable] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [aboutData, setAboutData] = useState({});

  useEffect(() => {
    setIsEditable(
      process.env.REACT_APP_AUTHORIZED_EMAILS.split(",").includes(user?.email)
    );

    const aboutRef = ref(db, "about");
    const unsubscribe = onValue(aboutRef, (snapshot) => {
      try {
        const data = snapshot.val();
        if (data) {
          setAboutData(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }

      return () => {
        unsubscribe();
      };
    });
  }, []);

  return (
    <div className="Intro">
      <div className="content">
        <h2>About</h2>
        <h3>{aboutData?.title}</h3>
        <p>{aboutData?.text}</p>
        <div className="get-in-touch-btn">Get In Touch</div>
      </div>
      <div
        className="image"
        style={{ backgroundImage: `url(${aboutData?.image})` }}
      ></div>
      {isEdit && (
        <EditAbout
          setIsEdit={setIsEdit}
          aboutImage={aboutData.image}
          aboutText={aboutData.text}
          aboutTitle={aboutData.title}
        />
      )}
      {user && isEditable && !isEdit && (
        <div className="create-btn" onClick={() => setIsEdit(true)}>
          <AddCircleOutlineIcon className="create-btn-icon" />
        </div>
      )}
    </div>
  );
}
